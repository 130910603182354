import React, { useState } from "react";
import emailjs from "@emailjs/browser";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";

import { BsTwitter } from "react-icons/bs";
import { FaGithub, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const Footer = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const { username, email, message } = formData;

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = () => {
		setLoading(true);

		emailjs
      .send(
        'service_if0tx44',
        'template_zl7cfmi',
        {
          from_name: formData.username,
          to_name: "Tech With Anirudh",
          from_email: formData.email,
          to_email: "hello@techwithanirudh.com",
          message: formData.message,
        },
        'Lo_-YH-G6-afumOsF'
      ).then(() => {
				setLoading(false);
				setIsFormSubmitted(true);
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<h2 className="head-text">Contact me</h2>

			<div className="app__footer-cards">
				<div
					className="app__footer-card"
					itemScope
					itemType="http://schema.org/Person"
				>
					<img src={images.email} alt="email" />
					<a
						href="mailto:hello@techwithanirudh.com"
						className="p-text"
						itemProp="email"
					>
						hello@techwithanirudh.com
					</a>
				</div>
				<p className="p-text">Or</p>
				<div className="app__footer-card">
					<div className="app__footer-social">
						<a
							href="https://www.youtube.com/channel/UCmse86kaxJ3n1dDT_bXDukg/"
							target="_blank"
							rel="noopener noreferrer"
							itemScope
							itemType="http://schema.org/Person"
							itemProp="sameAs"
						>
							<FaYoutube />
						</a>
						<a
							href="https://twitter.com/AnirudhWith"
							target="_blank"
							rel="noopener noreferrer"
							itemScope
							itemType="http://schema.org/Person"
							itemProp="sameAs"
						>
							<BsTwitter />
						</a>
						<a
							href="https://github.com/techwithanirudh"
							target="_blank"
							rel="noopener noreferrer"
							itemScope
							itemType="http://schema.org/Person"
							itemProp="sameAs"
						>
							<FaGithub />
						</a>
						<a
							href="https://www.linkedin.com/in/anirudhsriramb/"
							target="_blank"
							rel="noopener noreferrer"
							itemScope
							itemType="http://schema.org/Person"
							itemProp="sameAs"
						>
							<FaLinkedinIn />
						</a>
					</div>
				</div>
			</div>
			{!isFormSubmitted ? (
				<div className="app__footer-form app__flex">
					<div className="app__flex">
						<input
							className="p-text"
							type="text"
							placeholder="Your Name"
							name="username"
							value={username}
							onChange={handleChangeInput}
							data-hj-allow
						/>
					</div>
					<div className="app__flex">
						<input
							className="p-text"
							type="email"
							placeholder="Your Email"
							name="email"
							value={email}
							onChange={handleChangeInput}
							data-hj-allow
						/>
					</div>
					<div>
						<textarea
							className="p-text"
							placeholder="Your Message"
							value={message}
							name="message"
							onChange={handleChangeInput}
							data-hj-allow
						/>
					</div>
					<button
						type="button"
						className="p-text"
						onClick={handleSubmit}
					>
						{!loading ? "Send Message" : "Sending..."}
					</button>
				</div>
			) : (
				<div>
					<h3 className="head-text">
						Thank you for getting in touch!
					</h3>
				</div>
			)}
		</>
	);
};

export default AppWrap(
	MotionWrap(Footer, "app__footer"),
	"contact",
	"app__whitebg"
);
