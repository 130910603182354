import React, { useState, useEffect } from "react";
import { HiMenuAlt4, HiX, HiMoon, HiSun } from "react-icons/hi";
// Add a add icon
import { HiUserAdd } from "react-icons/hi";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Navbar.scss";

const Navbar = () => {
	const [toggle, setToggle] = useState(false);
	const [mode, setMode] = useState(localStorage.getItem("mode") || "light");
	const url = new URL(window.location);
	const subdomain = url.host.split(".")[0];
	const domain = url.host.replace(subdomain + ".", "");
	const navItems = [
		"home",
		"about",
		"projects",
		"skills",
		"testimonial",
		"blog",
		"gaming",
		"contact",
	];

	const handleMenuOpen = e => {
		e.stopPropagation();
		setToggle(prevState => !prevState);
	};

	function toggleMode() {
		if (mode === "light") {
			setMode("dark");
			localStorage.setItem("mode", "dark");
			document.body.classList.add("dark");
		} else {
			setMode("light");
			localStorage.setItem("mode", "light");
			document.body.classList.remove("dark");
		}
	}

	if (mode === "light") {
		document.body.classList.remove("dark");
	} else {
		document.body.classList.add("dark");
	}

	return (
		<nav className="app__navbar">
			<div className="app__navbar-logo">
				<img src={images.logo} alt="logo" />
			</div>
			<ul className="app__navbar-links">
				{navItems.map((item) => (
					<li className="app__flex p-text" key={`link-${item}`}>
						<div />
						{item === "blog" || item === "gaming" ? (
							<a
								href={`https://${item}.${domain}`}
								target="_blank"
								rel="noreferrer"
							>
								{item}
							</a>
						) : (
							<a href={`#${item}`}>{item}</a>
						)}
					</li>
				))}
			</ul>

			<div className="app__buttons-container">
				<div className="app__icon-button" onClick={toggleMode}>
					{mode === "light" ? <HiMoon /> : <HiSun />}
				</div>

				<div className="app__navbar-menu">
					<HiMenuAlt4 onClick={handleMenuOpen} />

					{toggle && (
						<motion.div
							whileInView={{ x: [300, 0] }}
							transition={{ duration: 0.85, ease: "easeOut" }}
						>
							<HiX onClick={() => setToggle(false)} />
							<ul>
								{navItems.map((item) => (
									<li key={item}>
										{item === "blog" ||
											item === "gaming" ? (
											<a
												href={`https://${item}.${domain}`}
												target="_blank"
												rel="noreferrer"
											>
												{item}
											</a>
										) : (
											<a
												href={`#${item}`}
												onClick={() => setToggle(false)}
											>
												{item}
											</a>
										)}
									</li>
								))}
							</ul>
						</motion.div>
					)}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
