import React from "react";
import { NavigationDots, SocialMedia } from "../components";

const AppWrap = (Component, idName, classNames) =>
	function HOC() {
		const year = new Date().getFullYear();
		const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

		React.useEffect(() => {
			const handleResize = () => {
				if (window.innerWidth < 768) {
					// location.reload();
					setIsMobile(true);
				} else {
					setIsMobile(false);
				}
			};
			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}, []);

		return (
			<div id={idName} className={`app__container ${classNames}`}>
				{!isMobile && <SocialMedia />}
				<div className="app__wrapper app__flex">
					<Component />

					<div className="copyright">
						{isMobile ? (
							<>
								<SocialMedia />
								<div>
									<p className="p-text">
										@{year} Tech With Anirudh
									</p>
									<p className="p-text">
										All rights reserved
									</p>
								</div>
							</>
						) : (
							<>
								<p className="p-text">
									@{year} Tech With Anirudh
								</p>
								<p className="p-text">All rights reserved</p>
							</>
						)}
					</div>
				</div>
				<NavigationDots active={idName} />
			</div>
		);
	};

export default AppWrap;
