import React from "react";
import Logo from "../../assets/logomobile.png";
import "./Loader.scss";

function Loader() {
	const year = new Date().getFullYear();
	var duration = 1;

	React.useEffect(() => {
		if (window.matchMedia("(display-mode: standalone)").matches) {
			duration = 0.5;
		}

		var scrollPosition = window.scrollY;
		window.scrollTo(0, 0);

		document.body.style.overflow = "hidden";

		setTimeout(() => {
			document
				.querySelector(".app__loader")
				?.classList.add("app__loader--hidden");
			document.body.style.overflow = "auto";

			if (scrollPosition < 1091.5) {
			   window.scrollTo(0, scrollPosition);
			} else {
			   window.scrollTo(0, 1091.5);
			   window.scrollTo(0, 0);
			}
		}, duration * 1000);
	}, []);

	return (
		<div className="app__loader">
			<div className="app__loader-item">
				<img src={Logo} alt="logo" />
			</div>
			<p className="app__loader-copyright">@{year} Tech With Anirudh</p>
		</div>
	);
}

export default Loader;
