import React from "react";

import {
	About,
	Footer,
	Header,
	Skills,
	Testimonial,
	Projects,
	CookieDialog
} from "./container";
import { Navbar, Loader } from "./components";
import "./App.scss";

import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

const App = () => {
	const url = new URL(window.location)
	const subdomain = url.host.split(".")[0];
  const domain = url.host.replace(subdomain + ".", "");

	// Analytics
	React.useEffect(() => {
		logEvent(analytics, "page_view");
	}, []);

	if (url.path === '/blog') {
		location.redirect(`https://blog.${domain}`)
	}

	return (
		<div className="app">
			<Navbar />
			<Loader />
		        <CookieDialog />
			<div className="app__sections">
				<Header />
				<About />
				<Projects />
				<Skills />
				<Testimonial />
				<Footer />
			</div>
		</div>
	);
};

export default App;
