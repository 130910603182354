import React, { useState, useEffect } from "react";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";
import "./Projects.scss";

const Work = () => {
	const [projects, setProjects] = useState([]);
	const [filterWork, setFilterWork] = useState([]);
	const [activeFilter, setActiveFilter] = useState("All");
	const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

	useEffect(() => {
		const query = '*[_type == "works"]';

		client.fetch(query).then((data) => {
			// Sort projects by order
			const sortedData = data.sort((a, b) => a.order - b.order);

			setProjects(sortedData);
			setFilterWork(data);
		});
	}, []);

	const handleWorkFilter = (item) => {
		setActiveFilter(item);
		setAnimateCard([{ y: 100, opacity: 0 }]);

		setTimeout(() => {
			setAnimateCard([{ y: 0, opacity: 1 }]);

			if (item === "All") {
				setFilterWork(projects);
			} else {
				setFilterWork(
					projects.filter((work) => work.tags.includes(item))
				);
			}
		}, 500);
	};

	return (
		<>
			<div className="app__buttons-between">
				<a href={`Anirudh_Sriram_Resume.pdf`} download className="button m-0">
					Resume
				</a>
				<h2 className="head-text">
					My <span>Projects</span>
				</h2>
				<a href={`Anirudh_Appreciation_letter_Signed.pdf`} download className="button m-0">
					Bosch Letter
				</a>
			</div>
			<div className="app__buttons-between-mobile">
				<a href={`Anirudh_Sriram_Resume.pdf`} download className="button">
					Download Resume
				</a>
				<a href={`Anirudh_Appreciation_letter_Signed.pdf`} download className="button">
					Download Bosch Letter
				</a>
			</div>

			<div className="app__project-filter">
				{[
					"Web App",
					"React JS",
					"Games",
					"Session",
					"No Code",
					"Low Code",
					"Collaboration",
					"All",
				].map((item, index) => (
					<div
						key={index}
						onClick={() => handleWorkFilter(item)}
						className={`app__project-filter-item app__flex p-text ${activeFilter === item ? "item-active" : ""
							}`}
					>
						{item}
					</div>
				))}
			</div>

			<motion.div
				animate={animateCard}
				transition={{ duration: 0.5, delayChildren: 0.5 }}
				className="app__project-portfolio"
			>
				{filterWork.map((work, index) => (
					<a
						className="app__project-item app__flex"
						key={index}
						target="_blank"
						rel="noreferrer"
						href={work.projectLink}
						itemScope
						itemType="http://schema.org/CreativeWork"
					>
						<div className="app__project-img app__flex">
							<img src={urlFor(work.imgUrl)} alt={work.title} itemProp="image" />

							<motion.div
								whileHover={{ opacity: [0, 1] }}
								transition={{
									duration: 0.25,
									ease: "easeInOut",
									staggerChildren: 0.5,
								}}
								className="app__project-hover app__flex"
							>
								<a
									href={work.projectLink}
									target="_blank"
									rel="noreferrer"
								>
									<motion.div
										whileInView={{ scale: [0, 1] }}
										whileHover={{ scale: [1, 0.9] }}
										transition={{ duration: 0.25 }}
										className="app__flex"
									>
										<AiFillEye />
									</motion.div>
								</a>
								{work.codeLink && (
									<a
										href={work.codeLink}
										target="_blank"
										rel="noreferrer"
									>
										<motion.div
											whileInView={{ scale: [0, 1] }}
											whileHover={{ scale: [1, 0.9] }}
											transition={{ duration: 0.25 }}
											className="app__flex"
										>
											<AiFillGithub />
										</motion.div>
									</a>
								)}
							</motion.div>
						</div>

						<div className="app__project-content app__flex">
							<h4 className="bold-text" itemProp="name">{work.title}</h4>
							<p className="p-text" itemProp="about" style={{ marginTop: 10 }}>
								{work.description}
							</p>

							<div className="app__project-tag app__flex">
								<p className="p-text" itemProp="genre">{work.tags[0]}</p>
							</div>
						</div>
					</a>
				))}
			</motion.div>
		</>
	);
};

export default AppWrap(
	MotionWrap(Work, "app__projects"),
	"projects",
	"app__primarybg"
);
