import React, { useEffect } from "react";
import "./CookieDialog.scss";

function CookieDialog() {
	useEffect(() => {
		const cookieDialog = document.querySelector(".app__cookie-dialog");
		const cookieDialogAccept = document.querySelector(
			".app__cookie-dialog__button-accept"
		);
		const cookieDialogClose = document.querySelector(
			".app__cookie-dialog__button-close"
		);

		var isAccepted = localStorage.getItem("cookieDialogAccepted");

		if (isAccepted === "true") {
			cookieDialog.classList.add("app__cookie-dialog--hidden");
		} else {
			cookieDialogAccept.addEventListener("click", () => {
				localStorage.setItem("cookieDialogAccepted", "true");
				cookieDialog.classList.add("app__cookie-dialog--hidden");
			});

			cookieDialogClose.addEventListener("click", () => {
				cookieDialog.classList.add("app__cookie-dialog--hidden");
			});
		}
	}, []);

	return (
		<div className="app__cookie-dialog">
			<div className="app__cookie-dialog__header">
				<div>
					<h2 className="app__cookie-dialog__title">New Version</h2>
				</div>
				<div>
					<button className="app__cookie-dialog__button app__cookie-dialog__button-close">
						&times;
					</button>
				</div>
			</div>
			<div className="app__cookie-dialog__text">
				<p>
        An updated version of this website is available. Check it out at <a href="https://new.techwithanirudh.com">new.techwithanirudh.com</a>.
    </p>
			</div>
			<div>
				<button className="app__cookie-dialog__button app__cookie-dialog__button-accept">
					Okay
				</button>
			</div>
		</div>
	);
}

export default CookieDialog;
