import React from "react";
import { BsTwitter } from "react-icons/bs";
import { FaGithub, FaLinkedinIn, FaYoutube } from "react-icons/fa";

const SocialMedia = () => (
	<div className="app__social app__socialmobile">
		<a
			href="https://www.youtube.com/channel/UCmse86kaxJ3n1dDT_bXDukg/"
			target="_blank"
			rel="noopener noreferrer"
		>
			<FaYoutube />
		</a>
		<a
			href="https://twitter.com/AnirudhWith"
			target="_blank"
			rel="noopener noreferrer"
		>
			<BsTwitter />
		</a>
		<a
			href="https://github.com/techwithanirudh"
			target="_blank"
			rel="noopener noreferrer"
		>
			<FaGithub />
		</a>
		<a
			href="https://www.linkedin.com/in/anirudhsriramb/"
			target="_blank"
			rel="noopener noreferrer"
		>
			<FaLinkedinIn />
		</a>
	</div>
);

export default SocialMedia;
